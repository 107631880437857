import { TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { preAuthorizationReportAutorizedDepreciation } from '../../../../../../../../../actions/accounting/preAuthorizedReports';
import { useForm } from '../../../../../../../../../hooks/useForm';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import moment from 'moment';
import swal from 'sweetalert';
import { saveModal } from '../../../../../../../../../helpers/UseSwal';

export const Depresiations = ({ depresiation = null, formValuesDetailsSummary }) => {

  const { expenseDepresiationTypes } = useSelector(state => state.expenseDepresiation)
  const { authorizationPrev } = useSelector(state => state.authorizationReport);

  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);

  const { formValues, setFormValues, handleInputChange } = useForm({
    expenseKeyType: 'EDIFICIOS',
    monthReal: 0,
    expenseSubtotal: 0.0,
    uuid: '',
  });

  const { expenseKeyType, monthReal, expenseSubtotal, uuid } = formValues;

  const { regimeSatCode, taxISRHonorarium, taxIVAHonorarium, taxISRLease, taxIVALease, month, year } = formValuesDetailsSummary;

  useEffect(() => {
    if (depresiation) {
      setFormValues(prevState => {
        return {
          expenseKeyType: depresiation.expenseKeyType,
          monthReal: depresiation.monthReal + 1,
          expenseSubtotal: depresiation.expenseSubtotal,
          uuid: depresiation.uuid,
        }
      })
    }
  }, [depresiation, setFormValues])


  const handleSubmit = (e) => {
    e.preventDefault();
    const expenseDepresiationType = expenseDepresiationTypes.filter(e => (e.expenseKey === expenseKeyType))[0];
    if (expenseDepresiationType.maxAmount && parseFloat(expenseSubtotal) >= parseFloat(expenseDepresiationType.maxAmount)) {
      expenseSubtotal = parseFloat(expenseDepresiationType.maxAmount);
    }
    const date = new Date(year, month, 0);
    const depresiationForMonth = (expenseSubtotal * expenseDepresiationType.porcentage) / 12;

    saveModal(true);
    const clasificateAt = moment(date).subtract(monthReal, 'months').format();

    let newDepresiation = {};
    newDepresiation['uuid'] = uuid;
    newDepresiation['id'] = new Date().getTime();
    newDepresiation['expenseKeyType'] = expenseKeyType;
    newDepresiation['months'] = expenseDepresiationType.depresiationMonths;
    newDepresiation['depresiationForMonth'] = depresiationForMonth;
    newDepresiation['monthReal'] = monthReal - 1;
    newDepresiation['expenseSubtotal'] = expenseSubtotal;
    if (newDepresiation['monthReal'] > month) {
      newDepresiation['depresiationReal'] = (month - 1) * depresiationForMonth;
    } else {
      newDepresiation['depresiationReal'] = newDepresiation['monthReal'] * depresiationForMonth;
    }

    newDepresiation['clasificateAt'] = clasificateAt;
    newDepresiation['updateAt'] = clasificateAt;

    if (!isValid(newDepresiation, expenseDepresiationType)) {
      saveModal(false);
      handleModal();
    }
    let isr = null;

    if (depresiation) {
      isr = JSON.parse(authorizationPrev.isrJson);
      isr.depresiations = isr.depresiations.map(
        e => (typeof (e.uuid) ? (e.id === depresiation.id ? e = newDepresiation : e) : e
        ))
    } else {
      if (Object.keys(authorizationPrev).length == 0 || authorizationPrev.isrJson === '{}') {
        if (regimeSatCode === '612') {
          authorizationPrev.isrJson = JSON.stringify(taxISRHonorarium);
          authorizationPrev.ivaJson = JSON.stringify(taxIVAHonorarium)
        }
        if (regimeSatCode === '606') {
          authorizationPrev.isrJson = JSON.stringify(taxISRLease);
          authorizationPrev.ivaJson = JSON.stringify(taxIVALease);
        }
        isr = JSON.parse(authorizationPrev.isrJson);
        isr.depresiations[0] = newDepresiation;
      } else {
        isr = JSON.parse(authorizationPrev.isrJson);
        isr.depresiations.push(newDepresiation)
      }
    }
    dispatch(preAuthorizationReportAutorizedDepreciation(JSON.stringify(isr), handleModal.bind()));
  }

  const isValid = (depresiation, expenseDepresiationType) => {
    if (depresiation['expenseKeyType'] === null || depresiation['monthReal'] < 0 || depresiation['depresiationForMonth'] <= 0) {
      swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
      return false;
    }
    let topMonths = expenseDepresiationType.depresiationMonths;
    if (depresiation['monthReal'] > topMonths) {
      swal('Error al guardar', 'El mes de depreciación no debe ser mayor a ' + topMonths, 'error');
      return false;
    }
    return true;

  }



  return (
    <div>
      <div className="modal-sm">
        <div className="text-center">
          {
            depresiation ? (
              <h4>Editar Depreciación</h4>
            ) : (
              <h4>Agregar Depreciación</h4>
            )
          }

        </div>
        <hr />

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                select
                variant="outlined"
                label="Tipo de depreciación"
                size="small"
                fullWidth={true}
                name="expenseKeyType"
                value={expenseKeyType}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
              >
                {
                  expenseDepresiationTypes.map((depreciation) => {
                    return (
                      <option key={depreciation.expenseKey} value={depreciation.expenseKey}>{depreciation.expenseKey}</option>
                    )
                  })
                }
              </TextField>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="UUID" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="uuid"
                value={uuid}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="Mes de depreciación" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="monthReal"
                value={monthReal}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="Subtotal de depreciación" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="expenseSubtotal"
                value={expenseSubtotal}
                onChange={handleInputChange}
              />
            </div>

          </div>
          <div className="col-md-12 text-right pr-0">
            <button type="submit" className="btn btn-primary ml-2">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}