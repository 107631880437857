import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { preAuthorizationReportAutorizedDepreciation } from '../../../../../../../../../actions/accounting/preAuthorizedReports';
import { useForm } from '../../../../../../../../../hooks/useForm';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import { Depresiations } from './depresiations';

const DepresiationsTable = ({ formValuesDetailsSummary }) => {

  const { formValues, setFormValues } = useForm({
    ivaTranslateds: 0.00,
    subtotal: 0.00,
    total: 0.00,
    totalIVATraslateds: {},
  });

  const { handleModal } = useContext(ModalContext);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);


  const [isrJson, setIrsJson] = useState({ depresiations: [] });

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(authorizationPrev).length > 0 && authorizationPrev.isrJson !== '{}') {
      setIrsJson(JSON.parse(authorizationPrev.isrJson))
    }
  }, [authorizationPrev])

  const deleteDepreciation = (id, e) => {

    swal({
      title: "¿Estas seguro?",
      text: "Una vez eliminado ¡no podrá recuperar su información!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          let isr = null
          isr = JSON.parse(authorizationPrev.isrJson);
          isr.depresiations = isr.depresiations.filter(
            e => (e.id !== id))

          dispatch(preAuthorizationReportAutorizedDepreciation(JSON.stringify(isr)));

          swal("¡La depreciación ha sido eliminado!", {
            icon: "success",
          });
        }
      })
  }

  return (isrJson.depresiations.length > 0 &&
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h5>Depreciaciones</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th>UUID</th>
                <th>Tipo depreciación</th>
                <th>Mes de depreciación</th>
                <th>Depreciación por mes</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {
                isrJson.depresiations.map((depresiation, index) => {
                  return (depresiation.expenseSubtotal &&
                    <tr key={index} className="text-center">
                      <td> {depresiation.uuid} </td>
                      <td> {depresiation.expenseKeyType} </td>
                      <td>{depresiation.monthReal + 1}</td>
                      <td><strong> $ {parseFloat(depresiation.depresiationForMonth).toFixed(2)}</strong></td>
                      <td className='row'>
                        <button
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Editar Depreciación"
                          className="btn btn-sm btn-outline-primary px-2"
                          onClick={() => handleModal(
                            <Depresiations
                              depresiation={depresiation}
                              formValuesDetailsSummary={formValuesDetailsSummary}
                            />
                          )}
                        >
                          <FontAwesomeIcon className="fa-lg" icon={"pencil-alt"} />
                        </button>

                        <button
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Eliminar Depreciación"
                          className="btn btn-sm btn-outline-danger px-2 ml-2"
                          onClick={deleteDepreciation.bind(this, depresiation.id)}
                        >
                          <FontAwesomeIcon className="fa-lg" icon={"trash"} />
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default DepresiationsTable;
