import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { preAuthorizationReportAutorized, preAuthorizedReportCheckingToken, preAutorizedReportUpdateStart } from '../../../../../../actions/accounting/preAuthorizedReports';
import { regimesStartLoading } from '../../../../../../actions/catalogs/regimes';
import { initialoptionIsrHonorarium } from '../../../../../../helpers/accounting/obtainIsrHonorariumPorcentage';
import { initialOptionIsrPlataform } from '../../../../../../helpers/accounting/obtainIsrPlataformPorcentaje';
import { initialoptionIsrSimplified } from '../../../../../../helpers/accounting/obtainIsrSimplifiedPorcentage';
import { clasificateExpenses, clasificateIncomes, consideredsExpenses, consideredsIncomes } from '../../../../../../helpers/clasificateDownloadAccounting';
import { obtainMonth } from '../../../../../../helpers/declarationPeriod';
import { initialoptionIsr } from '../../../../../../helpers/obtainIsrPorcentage';
import { initialOptionLease } from '../../../../../../helpers/obtainLeasePorcentage';
import { useForm } from '../../../../../../hooks/useForm';
import { HonorariumInformation } from './informationReport/HonorariumInformation';
import { LeaseInformation } from './informationReport/LeaseInformation';
import { PlataformInformation } from './informationReport/PlataformInformation';
import { RifInformation } from './informationReport/RifInformation';
import { SimplifiedInformation } from './informationReport/SimplifiedInformation';
import { CommentsScreen } from './options/comments/CommentsScreen';
import { Complement } from './options/complement/expense/Complement';
import { ComplementIncome } from './options/complement/income/ComplementIncome';
import Expenses from './options/expenses/expenses';
import Income from './options/income/income';
import Retention from './options/retention/retention';
import TaxCalculation from './options/taxCalculation/taxCalculation';

export const DetailsPreReportScreen = () => {
  const { token } = useParams();

  const { regimes } = useSelector(state => state.regime)
  const { authorizationReport, periods, profile, authorizations } = useSelector(state => state.authorizationReport);
  const { incomes, statusIncomeDownload } = useSelector(state => state.income);
  const { expenses, statusExpenseDownload } = useSelector(state => state.expense);
  const { expenseTypes } = useSelector(state => state.expenseType)
  const { retentions } = useSelector(state => state.retention);

  const { commentJson, isAuthorized, period, id } = authorizationReport;

  const dispatch = useDispatch();

  const { formValues, setFormValues } = useForm({
    comments: null,
    informationDiots: [],
    depresiations: [],

    subtotalExpenseAutorized: 0.00,
    subtotalExpenseNotAutorized: 0.00,
    subtotalExpensePersonalAutorized: 0.00,
    totalExpenseAutorized: 0.00,
    totalExpenseNotAutorized: 0.00,
    totalExpensePersonalAutorized: 0.00,

    subtotalExpenseRate0: 0.00,
    totalExpenseRate0: 0.00,
    subtotalExpenseRate16: 0.00,
    totalExpenseRate16: 0.00,

    subtotalIncomesAutorized: 0.00,
    totalIncomesAutorized: 0.00,
    totalIncomesNotAutorized: 0.00,

    subtotalIncomesGeneralPublic: 0.00,
    totalIncomesGeneralPublic: 0.00,

    subtotalIncomesRate16: 0.00,
    totalIncomesRate16: 0.00,

    subtotalIncomesRate0: 0.00,
    totalIncomesRate0: 0.00,

    totalLIVA: 1.00,

    totalTaxesRate16: 0.00,

    expenseTotalTaxeTras: 0.00,

    taxISRHonorarium: {
      expensesAccumulateds: 0.00,
      expensesAccumulatedsTotal: 0.00,
      expensesSubtotalAutorized: 0.00,

      incomesAccumulateds: 0.00,
      incomesAccumulatedsTotal: 0.00,
      incomesSubtotalAutorized: 0.0,

      depresiationFixedAsset: 0.00,
      depresiationAcumulated: 0.00,
      depresiations: [],

      nameMonth: '',

      retentionPrevMonths: 0.00,
      retentionPaidPrev: 0.00,

      taxableBase: 0.00,
      ISR: initialoptionIsrHonorarium
    },
    taxIVAHonorarium: {
      subtotalIncome16: 0.00,
      subtotalIncome0: 0.00,
      subtotalIncomeExento: 0.00,
      totalIvaCreditable: 0.00,
      totalIvaRetained: 0.00,
      totalTaxe16: 0.00,
      IVAMonthsPrev: 0.00,
      subtotalIVA: 0.00,
      totalIVA: 0.00,
    },
    taxIVASimplified: {
      subtotalIncome16: 0.00,
      subtotalIncome0: 0.00,
      subtotalIncomeExento: 0.00,
      totalIvaCreditable: 0.00,
      totalIvaRetained: 0.00,
      totalTaxe16: 0.00,
      IVAMonthsPrev: 0.00,
      subtotalIVA: 0.00,
      totalIVA: 0.00,
    },
    taxISRSimplified: {
      expensesAccumulateds: 0.00,
      expensesAccumulatedsTotal: 0.00,
      expensesSubtotalAutorized: 0.00,

      incomesAccumulateds: 0.00,
      incomesAccumulatedsTotal: 0.00,
      incomesSubtotalAutorized: 0.00,

      nameMonth: '',

      ISR: initialoptionIsrSimplified
    },
    taxISRPlataformsTechnologies: {
      serviceAlienation: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceAlineationClasified: 0.00,
        subtotalServiceLendingClasified: 0.00,
        totalServiceAlienationFinal: 0.00,
        totalServiceLendingFinal: 0.00,
        totalAlienationPlataform: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalIsr: 0.00,
        totalLendingPlataform: 0.00,
        totalService: 0.00,
        totalRetained: 0.00,
      },
      serviceGround: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceDealerClasified: 0.00,
        subtotalServicePassengersClasified: 0.00,
        totalIsr: 0.00,
        totalService: 0.00,
        totalServicePassengersFinal: 0.00,
        totalServiceDealerFinal: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalDealerPlataform: 0.00,
        totalPassengerPlataform: 0.00,
        totalRetained: 0.00,
      },
      serviceLodging: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceLodgingClasified: 0.00,
        totalServiceLodgingFinal: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalIsr: 0.00,
        totalLodgingPlataform: 0.00,
        totalService: 0.00,
        totalRetained: 0.00,
      },
      totalIncomes: 0.00,
      totalForIntermediaries: 0.00,
      totalForUsers: 0.00,
    },
    taxIVAPlataformsTechnologies: {
      ivaCaused: 0.00,
      ivaExpenseTotal: 0.00,
      ivaPeriodsPrevius: 0.00,
      ivaToDeclared: 0.00,
      optionIva: {
        porcentage: .16
      },
      retentionPlataform: 0.00,
      totalIva: 0.00,
    },
    taxISRRIF: {
      calculatedBase: 0.00,
      calculatedBaseTotal: 0.00,
      deductionIsr: 0.00,
      expensesHigherToIncome: 0.00,
      expensesHigherToIncomePrev: 0.00,
      expensesHigherToIncomePrevFinal: 0.00,
      ISR: initialoptionIsr,
      isrDetermined: 0.00,
      isrMarginal: 0.00,
      reductionAmount: 0.00,
      subtotalIncomes: 0.00,
      subtotalExpenseAutorized: 0.00,
      subtotalIncomesAutorized: 0.00,
      totalIsr: 0.00,
      utility: 0.00
    },
    taxIVARIF: {
      deductionIsr: 0.00,

      ivaReduced: 0.00,

      ivaCharged: 0.00,
      ivaCreditable: 0.00,

      ivaPublicGeneral: 0.00,
      ivaRetained: 0.00,

      ivaToCharge: 0.00,
      IVAMonthsPrev: 0.00,

      totalTax16Expense: 0.00,
      totalTaxforeignExpense: 0.00,

      subtotalExpense0: 0.00,
      subtotalExpense16: 0.00,
      subtotalExpenseForeign: 0.00,
      subtotalIncomes0: 0.00,
      subtotalIncomes16: 0.00,
      subtotalIncomesExento: 0.00,
      subtotalIncomesPublicGeneral: 0.00,
      totalIva: 0.00,
      totalLIVA: 1.00,
    },
    taxISRLease: {
      depresiationFixedAsset: 0.00,
      depresiations: [],
      blindDeduction: 0.00,
      blindPorcentage: 0.35,
      ISR: initialOptionLease,
      isBlindDeduction: true,
      subtotalPredialExpense: 0.00,
      taxableBase: 0.00,
      TotalISRRetained: 0.00,
      totalISR: 0.00
    },
    taxIVALease: {
      IVAMonthsPrev: 0.00,
      totalRateIncome16: 0.00,
      totalRateinedIncomeIVA: 0.00,
      totalIvaTraslated: 0.0,
      totalIVAExpense: 0.00,
      totalIVA: 0.00
    },

    regimeSelected: null,
    regimeSatCode: '',
    month: null,
    monthName: '',
    year: '',

    isrJson: {},
    ivaJson: {},
    resume: {},

    ISRPlataform: {},
  });

  const { informationDiots, regimeSatCode, taxISRHonorarium, taxIVAHonorarium, taxISRLease, taxISRRIF,
    taxIVALease, taxIVARIF, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies, reportId,
    taxISRSimplified, taxIVASimplified, subtotalIncomesAutorized, totalExpensePersonalAutorized,
    subtotalExpenseNotAutorized, subtotalExpenseAutorized, totalIVATraslatedIncomeAutorized,
    totalIVARetainedIncomeAutorized, totalISRIncomeAutorized, totalIncomesAutorized, totalIVAExpenseAutorized,
    totalExpenseAutorized, month, year, regimeSelected } = formValues;

  const { ivaCaused, ivaExpenseTotal, ivaPeriodsPrevius, ivaToDeclared, optionIva, retentionPlataform, totalIva } = taxIVAPlataformsTechnologies;
  const { serviceAlienation, serviceGround, serviceLodging, totalIncomes, totalForIntermediaries, totalForUsers } = taxISRPlataformsTechnologies;

  const { isBlindDeduction, blindDeduction, blindPorcentage, depresiationFixedAsset, ISR, subtotalPredialExpense, taxableBase, totalISR, TotalISRRetained } = taxISRLease

  const { IVAMonthsPrev, subtotalIncome16, subtotalIncome0, subtotalIncomeExento, subtotalIVA, totalIvaRetained, totalIvaCreditable, totalTaxe16, totalIVA } = taxIVAHonorarium;

  const isrTotalCost = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const subtotalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(isrTotalCost)

  const considered = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const ivaTotalAuth = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0

  const consideredExpenseTotalAcreditable = parseFloat(subtotalExpenseAutorized) + parseFloat(considered)

  const consideredIvaAcreditable606 = parseFloat(taxIVALease.totalIVAExpense) + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable612 = parseFloat(taxIVAHonorarium.totalIvaCreditable) + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable621 = parseFloat(taxIVARIF.ivaCreditable) + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable626 = parseFloat(totalIVAExpenseAutorized) + parseFloat(ivaTotalAuth)

  // //console.log('consideredIvaAcreditable612',consideredIvaAcreditable612)
  // //console.log('consideredIvaAcreditable606',consideredIvaAcreditable606)
  // //console.log('consideredIvaAcreditable621',consideredIvaAcreditable621)
  // //console.log('consideredIvaAcreditable626',consideredIvaAcreditable626)
  // //console.log('consideredExpenseTotalAcreditable',consideredExpenseTotalAcreditable)

  const [totalIsrState, setTotalIsrState] = useState(0.00);
  const prevFormValues = useRef();

  useEffect(() => {
    let totalIsr = parseFloat(
      parseFloat(serviceAlienation.totalIsr) +
      parseFloat(serviceGround.totalIsr) +
      parseFloat(serviceLodging.totalIsr)
    ).toFixed(2)

    if (parseFloat(totalIsr) < 0) {
      totalIsr = 0.00;
    }
    setTotalIsrState(totalIsr)
  }, [serviceAlienation, serviceGround, serviceLodging, setTotalIsrState])

  useEffect(() => {
    if (month) {
      setFormValues(prevState => {
        return {
          ...prevState,
          monthName: obtainMonth(parseInt(month) - 1)
        }
      })
    }
  }, [month, setFormValues])

  useEffect(() => {
    if (regimes.length === 0) {
      dispatch(regimesStartLoading());
    }
  }, [dispatch, regimes])

  const reumenFunction = () => {
    let flag = ''
    let JsonResume = {}

    let consideredsInc = []
    let consideredsExp = []

    let incomesUUID = ''
    let expensesUUID = ''
    let retentionsUUID = ''

    let expensesAutorized = ''
    let incomesAutorized = ''
    let retentionsAutorized = ''

    // FOrmulas para los ingresos considerados
    const clasificationsIncomes = clasificateIncomes(incomes, month, regimeSatCode, year);
    if (clasificationsIncomes.length > 0) {
      consideredsInc = consideredsIncomes(clasificationsIncomes);
      incomesUUID = consideredsInc.map(data => {
        return data.uuid
      })
      incomesAutorized = incomesUUID.join('|').replace(/\[|\]/g, '');
    }

    // FOrmulas para las Deducciones autorizadas
    if (expenseTypes.length == 0) {
      return
    }
    const clasificationsExpenses = clasificateExpenses(expenseTypes, expenses, month, regimeSatCode, year);
    if (clasificationsExpenses.length > 0) {
      consideredsExp = consideredsExpenses(expenseTypes, clasificationsExpenses, regimeSatCode);
      expensesUUID = consideredsExp.map(data => {
        return data.uuid
      })
      expensesAutorized = expensesUUID.join('|').replace(/\[|\]/g, '');
    }

    // FOrmulas para las Retenciones
    if (retentions.length > 0) {
      retentionsUUID = retentions.map(data => {
        return data.uuid
      })
      retentionsAutorized = retentionsUUID.join('|').replace(/\[|\]/g, '');
    }

    if (regimeSatCode === '625') {
      (taxIVAPlataformsTechnologies.totalIva >= 0) ? flag = false : flag = true

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": totalIncomes,
          "DeduccionCiega": 0,

          "IngresosPorServicioTerrestre": serviceGround.totalService,
          "IngresosPorServicioHospedaje": serviceLodging.totalService,
          "IngresosPorEnajenacion": serviceAlienation.totalLendingPlataform,

          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,

          "TotalIva": taxIVAPlataformsTechnologies.totalIva,
          "TotalIsr": totalIsrState,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {

          "TotalIvaJson": {

            "IngresosMedianteIntermediarios": totalForIntermediaries,
            "IngresosMedianteUsuario": totalForUsers,
            "IngresosTotales": totalIncomes,
            "Tasa": optionIva.porcentage * 100,
            "IVACausado": ivaCaused,
            "IVAGastosAcreditable": consideredIvaAcreditable626,
            "RetencionesDeIVAPorPlataformaTecnologica": retentionPlataform,
            "IVADelPeriodoADeclarar": retentionPlataform,
            "IVAAAcreditarDePeriodosAnteriores": ivaPeriodsPrevius,

            "TotalIva": totalIva,
            "IsAFavor": flag
          },
          "TotalIsrJSon": {
            "ServicioTerrestre": {
              "IngresosIntermediariosServicioDePasajeros": serviceGround.totalPassengerPlataform,
              "IngresosIntermediariosEntregaBienes": serviceGround.totalDealerPlataform,
              "IngresosDirectosServicioDePasajeros": serviceGround.totalServicePassengersFinal,
              "IngresosDirectosEntregaBienes": serviceGround.totalServiceDealerFinal,
              "IngresosTotales": serviceGround.totalService,
              "Tasa": serviceGround.ISR.porcentage * 100,
              "ISRCausado": serviceGround.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceGround.totalRetained,
              "ISRACargo": serviceGround.totalIsr,
            },
            "ServicioHospedaje": {
              "IngresosPorIntermediarios": serviceLodging.totalLodgingPlataform,
              "IngresosDirectos": serviceLodging.totalServiceLodgingFinal,
              "IngresosTotales": serviceLodging.totalService,
              "Tasa": serviceLodging.ISR.porcentage * 100,
              "IsrCausado": serviceLodging.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceLodging.totalRetained,
              "ISRACargo": serviceLodging.totalIsr,
            },
            "EnajenacionYPrestacionServicios": {
              "IngresosIntermediariosEnajenacion": serviceAlienation.totalAlienationPlataform,
              "IngresosIntermediariosPrestacionServicios": serviceAlienation.totalLendingPlataform,
              "IngresosDirectosEnajenacion": serviceAlienation.totalServiceAlienationFinal,
              "IngresosDirectosPrestacionServicios": serviceAlienation.totalServiceLendingFinal,
              "IngresosTotalesMenosRetenciones": serviceAlienation.totalService,
              "Tasa": serviceAlienation.ISR.porcentage * 100,
              "ISRCausado": serviceAlienation.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceAlienation.totalRetained,
              "ISRACargo": serviceAlienation.totalIsr,
            }
          },

          "TotalesIncomesJson": {
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },

          "TotalesExpensesJson": {
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized
      }
    }
    if (regimeSatCode === '606') {
      (taxIVALease.totalIVA >= 0) ? flag = false : flag = true

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": blindDeduction,

          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,

          "DeduccionesAutorizadas": 0,
          "DeduccionesNoAutorizadas": 0,
          "DeduccionesPersonales": totalExpensePersonalAutorized,

          "TotalIva": taxIVALease.totalIVA,
          "TotalIsr": taxISRLease.totalISR,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {

          "TotalIvaJson": {
            "IvaCausado": taxIVALease.totalIvaTraslated,
            "IvaRetenido": taxIVALease.totalRateinedIncomeIVA,
            "IvaAcreditable": consideredIvaAcreditable606,
            "IvaFavorPeriodosAnteriores": taxIVALease.IVAMonthsPrev,
            "TotalIva": taxIVALease.totalIVA,
            "IsAFavor": flag
          },
          "TotalIsrJSon": {
            "IsCiega": isBlindDeduction,
            "DeduccionGasgos": subtotalISR,
            "ImpuestoPredial": subtotalPredialExpense,
            "DeduccionCiega": blindDeduction,
            "DepreciacionActivosFijos": depresiationFixedAsset,
            "IngresosMes": subtotalIncomesAutorized,
            "BaseGravable": taxableBase,
            "LimiteInferior": taxISRLease.ISR.lowerLimit,
            "TotalGravable": taxISRLease.ISR.subtractLimitLower,
            "Tasa": taxISRLease.ISR.porcentage * 100,
            "TotalTasa": taxISRLease.ISR.forPorcentage,
            "CuotaFija": taxISRLease.ISR.fixedFee,
            "Acargo": taxISRLease.ISR.plusFixedFee,
            "IsrRetenido": TotalISRRetained,
            "TotalISR": taxISRLease.totalISR,
            "IsAFavor": false
          },
          "TotalesIncomesJson": {
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },
          "TotalesExpensesJson": {
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized
      }
    }
    if (regimeSatCode === '612') {
      (taxIVAHonorarium.totalIVA >= 0) ? flag = false : flag = true

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,

          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,

          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,

          "TotalIva": taxIVAHonorarium.totalIVA,
          "TotalIsr": taxISRHonorarium.ISR.retentionToPay,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {

          "TotalIvaJson": {

            "ActividadesGravadasALaTasaDel16": subtotalIncome16,
            "ActividadesGravadasALaTasaDel0": subtotalIncome0,
            "ActividadesExentas": subtotalIncomeExento,
            "IVACobradoDelPeriodoALaTasaDel16": totalTaxe16,
            "IVAAcreditableDelPeriodo": consideredIvaAcreditable612,
            "IvaRetenido": totalIvaRetained,
            "IvaAFavorDePeriodosAnteriores": IVAMonthsPrev,

            "TotalIva": taxIVAHonorarium.totalIVA,
            "IsAFavor": flag
          },
          "TotalIsrJSon": {

            "IngresosDelMes": taxISRHonorarium.incomesSubtotalAutorized,
            "IngresosAcumuladosHasta": taxISRHonorarium.incomesAccumulatedsTotal,
            "GastosDelMes": subtotalISR,
            "GastosAcumuladosHasta": taxISRHonorarium.expensesAccumulatedsTotal,
            "DepreciacionDeActivosFijos": taxISRHonorarium.depresiationFixedAsset,
            "DepreciacionDeActivosFijosAcumulada": taxISRHonorarium.depresiationAcumulated,
            "BaseGravable": taxISRHonorarium.taxableBaseTotal,
            "LimiteInferior": taxISRHonorarium.ISR.lowerLimit,
            "ExcedenteDelLimiteInferior": taxISRHonorarium.ISR.surplusLowerLimit,
            "Porcentaje": taxISRHonorarium.ISR.porcentage * 100,
            "ImpuestoMarginal": 0,
            "CuotaFija": taxISRHonorarium.ISR.fixedFee,
            "ImpuestosAntesDeRetenciones": taxISRHonorarium.ISR.taxeBeforeRetentions,
            "IsrDeRetenciones": taxISRHonorarium.totalISRIncomeAutorized,
            "RetencionesDeMesesAnteriores": taxISRHonorarium.retentionPrevMonths,
            "IsrPagadoDeMesesAnteriores": taxISRHonorarium.retentionPaidPrev,

            "TotalISR": taxISRHonorarium.ISR.retentionToPay,
            "IsAFavor": false

          },

          "TotalesIncomesJson": {
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },

          "TotalesExpensesJson": {
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized
      }
    }
    if (regimeSatCode === '621') {
      (taxIVARIF.totalIva >= 0) ? flag = false : flag = true

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,

          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,

          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,

          "TotalIva": taxIVARIF.totalIva,
          "TotalIsr": taxISRRIF.totalIsr,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {

          "TotalIvaJson": {

            "IngresosPorVentasAlPublicoEnGeneral": taxIVARIF.subtotalIncomesPublicGeneral,
            "IngresosFacturadosALaTasaDel16": taxIVARIF.subtotalIncomes16,
            "IngresosFacturadosALaTasaDel0": taxIVARIF.subtotalIncomes0,
            "IngresosExentos": taxIVARIF.subtotalIncomesExento,
            "ComprasYGastosPagadosConTasa16": taxIVARIF.subtotalExpense16,
            "ComprasYGastosPagadosEnLaRegionFronteriza": taxIVARIF.subtotalExpenseForeign,
            "ComprasYGastosPagadosALaTasaDel0": 0,
            "ProporcionUtilizadaConformeALaLIVA": taxIVARIF.totalLIVA,
            "IVARetenido": taxIVARIF.ivaRetained,
            "IVAPorPublicoEnGeneral": taxIVARIF.ivaPublicGeneral,
            "PorcentajeReduccion": taxIVARIF.deductionIsr * 100,
            "Reduccion": taxIVARIF.ivaReduced,
            "IVACobrado": taxIVARIF.ivaCharged,
            "IVAAcreditable": consideredIvaAcreditable621,
            "IVAACargo": taxIVARIF.ivaToCharge,
            "IVAAFavorDePeriodosAnteriores": taxIVARIF.IVAMonthsPrev,

            "TotalIva": taxIVARIF.totalIva,
            "IsAFavor": flag
          },
          "TotalIsrJSon": {

            "IngresosDelMes": subtotalIncomesAutorized,
            "GastosDelMes": subtotalISR,
            "DiferenciaPorGastosMayores": taxISRRIF.expensesHigherToIncome,
            "Utilidad": taxISRRIF.utility,
            "DiferenciaPorGastosMayoresPrevios": taxISRRIF.expensesHigherToIncomePrev,
            "BaseParaCalculo": taxISRRIF.calculatedBase,
            "LimiteInferior": taxISRRIF.lowerLimit,
            "ExcendeDelLimite": taxISRRIF.subtotalIncomes,
            "Tasa": taxISRRIF.ISR.porcentage * 100,
            "ISRMarginal": taxISRRIF.isrMarginal,
            "CuotaFija": taxISRRIF.ISR.fixedFee,
            "ISRDeterminado": taxISRRIF.isrDetermined,
            "PorcentajeReducción": taxISRRIF.deductionIsr * 100,
            "Reduccion": taxISRRIF.reductionAmount,

            "TotalISR": taxISRRIF.totalIsr,
            "IsAFavor": false

          },

          "TotalesIncomesJson": {
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },

          "TotalesExpensesJson": {
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized
      }
    }
    if (regimeSatCode === '626') {
      (taxIVASimplified.totalIVA >= 0) ? flag = false : flag = true

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,

          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,

          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": 0,

          "TotalIva": taxIVASimplified.totalIVA,
          "TotalIsr": taxISRSimplified.ISR.retentionToPay,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {

          "TotalIvaJson": {
            "ActividadesGravadasALaTasaDel16": taxIVASimplified.subtotalIncome16,
            "ActividadesGravadasALaTasaDel0": taxIVASimplified.subtotalIncome0,
            "ActividadesExentas": taxIVASimplified.subtotalIncomeExento,
            "IVACobradoDelPeriodoALaTasaDel16": taxIVASimplified.totalTaxe16,
            "IVAAcreditableDelPeriodo": consideredIvaAcreditable626,
            "IvaRetenido": taxIVASimplified.totalIvaRetained,
            "IvaAFavorDePeriodosAnteriores": taxIVASimplified.IVAMonthsPrev,

            "TotalIva": taxIVASimplified.totalIVA,
            "IsAFavor": flag
          },
          "TotalIsrJSon": {
            "IngresosDelMes": taxISRSimplified.incomesSubtotalAutorized,
            "Tasa": taxISRSimplified.ISR.porcentage,
            "ImpuestosAntesDeRetenciones": taxISRSimplified.ISR.taxeBeforeRetentions,
            "IsrDeRetenciones": taxISRSimplified.ISR.isrRetentions,

            "TotalISR": taxISRSimplified.ISR.retentionToPay,
            "IsAFavor": false
          },
          "TotalesIncomesJson": {
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },
          "TotalesExpensesJson": {
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized
      }
    }

    return JsonResume;
  }

  const handleSaveCalculation = () => {

    const regime = regimes.filter(e => (e.id === parseInt(authorizationReport.regimeId)))[0];

    let satCode = regime?.satCode
    const resumenPetition = reumenFunction();

    let summaryEmpty = !(autorizedReport.resume != null || autorizedReport.resume != '' || autorizedReport.resume != {})
    let summaryEmptyOther = (authorizationReport.resume === '{}' || authorizationReport.resume === {}) && resumenPetition

    let petition = {
      sendReport: authorizationReport.sendReport,
      reportId: id,
      isAuthorized: authorizationReport.isAuthorized,
      isFromToken: true
    }
    let dispatchRequired = false;

    if (satCode === '625') {
      if (summaryEmptyOther) {
        petition.isrJson = JSON.stringify(taxISRPlataformsTechnologies);
        petition.ivaJson = JSON.stringify(taxIVAPlataformsTechnologies);
        petition.Resume = JSON.stringify(resumenPetition);
        dispatchRequired = true
      }
    }
    if (satCode === '606') {
      if (summaryEmptyOther) {
        petition.isrJson = JSON.stringify(taxISRLease);
        petition.ivaJson = JSON.stringify(taxIVALease);
        petition.Resume = JSON.stringify(resumenPetition);
        dispatchRequired = true
      }
    }
    if (satCode === '621') {
      if (summaryEmptyOther) {
        petition.isrJson = JSON.stringify(taxISRRIF);
        petition.ivaJson = JSON.stringify(taxIVARIF);
        petition.Resume = JSON.stringify(resumenPetition);
        dispatchRequired = true
      }
    }
    if (satCode === '612') {
      if (summaryEmptyOther) {
        petition.isrJson = JSON.stringify(taxISRHonorarium);
        petition.ivaJson = JSON.stringify(taxIVAHonorarium);
        petition.Resume = JSON.stringify(resumenPetition);
        dispatchRequired = true
      }
    }
    if (satCode === '626') {
      if (summaryEmptyOther) {
        petition.isrJson = JSON.stringify(taxISRSimplified);
        petition.ivaJson = JSON.stringify(taxIVASimplified);
        petition.Resume = JSON.stringify(resumenPetition);
        dispatchRequired = true
      }
    }

    if (dispatchRequired) {
      dispatch(preAutorizedReportUpdateStart(petition));
    }
  }

  useEffect(() => {
    if (Object.keys(authorizationReport).length === 0) {
      reumenFunction()
      dispatch(preAuthorizedReportCheckingToken(token));
    }
  }, [authorizationReport, dispatch, token])

  useEffect(() => {
    if (commentJson) {
      setFormValues(prevState => {
        return {
          ...prevState,
          comments: commentJson
        }
      });
    }
  }, [commentJson, setFormValues]);

  // useEffect(() => {
  //   console.info('EFFECT - authorizationReport',authorizationReport.resume)
  //   if(authorizationReport.resume === '{}' || authorizationReport.resume === {}){ 
  //     console.info('Si entro')
  //     handleSaveCalculation()
  //   }
  // }, [ formValues ])

  // useEffect(() => {
  //   if (
  //     (authorizationReport.resume === '{}')||
  //     (JSON.stringify(prevFormValues.current) !== JSON.stringify(formValues))
  //   ) {
  //     handleSaveCalculation();
  //   }

  //   prevFormValues.current = formValues;
  // }, [formValues, authorizationReport]);

  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    if (
      (!hasRun) &&
      (authorizationReport.resume === '{}') ||
      (JSON.stringify(prevFormValues.current) !== JSON.stringify(formValues))
    ) {
      handleSaveCalculation();
      setHasRun(true); // Marcar la función como ejecutada
    }

    prevFormValues.current = formValues;
  }, [formValues, authorizationReport, hasRun]);

  useEffect(() => {
    if (Object.keys(authorizationReport).length > 0 && regimes.length > 0) {
      const regime = regimes.filter(e => (e.id === parseInt(authorizationReport.regimeId)))[0];
      let petition = {
        regimeSelected: regime,
        regimeSatCode: regime.satCode,
        month: periods.month,
        year: periods.year,
      }

      if (regime.satCode === '625') {
        petition['taxISRPlataformsTechnologies'] = { ...JSON.parse(authorizationReport.isrJson) }
        petition['taxIVAPlataformsTechnologies'] = { ...JSON.parse(authorizationReport.ivaJson) }
      }
      if (regime.satCode === '621') {
        petition['taxISRRIF'] = { ...JSON.parse(authorizationReport.isrJson) }
        petition['taxIVARIF'] = { ...JSON.parse(authorizationReport.ivaJson) }
      }
      if (regime.satCode === '606') {
        petition['taxISRLease'] = { ...JSON.parse(authorizationReport.isrJson) }
        petition['taxIVALease'] = { ...JSON.parse(authorizationReport.ivaJson) }
      }
      if (regime.satCode === '612') {
        petition['taxISRHonorarium'] = { ...JSON.parse(authorizationReport.isrJson) }
        petition['taxIVAHonorarium'] = { ...JSON.parse(authorizationReport.ivaJson) }
      }
      if (regime.satCode === '626') {
        petition['taxISRSimplified'] = { ...JSON.parse(authorizationReport.isrJson) }
        petition['taxIVASimplified'] = { ...JSON.parse(authorizationReport.ivaJson) }
      }

      setFormValues(prevState => {
        return {
          ...prevState,
          ...petition
        }
      })
    }
  }, [authorizationReport, regimes, setFormValues, periods]);

  const autorizedReport = () => {
    let petition = {
      isAuthorized: true
    }
    if (token) {
      dispatch(preAuthorizationReportAutorized(token, petition))
    }
  }

  return (
    <div className="col-md-12">
      {Object.keys(authorizationReport).length === 0 ?
        (
          <div className="alert alert-danger col-md-12 text-center" role="alert">
            No existe información de algún reporte.
          </div>
        )
        :
        (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <h4>RESUMEN DEL MES - {period}</h4>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right">
                      {isAuthorized ?
                        (
                          <button className="btn btn-success btn-sm" disabled>Este reporte ha sido autorizado</button>
                        )
                        :
                        (
                          <button className="btn btn-success btn-sm" onClick={autorizedReport}>Autorizo mi reporte</button>
                        )
                      }
                    </div>
                    <br /><br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
              <div className="row">
                {formValues.regimeSatCode === '625' &&
                  <PlataformInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '606' &&
                  <LeaseInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '612' &&
                  <HonorariumInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '621' &&
                  <RifInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '626' &&
                  <SimplifiedInformation
                    detailsFormValues={formValues}
                  />
                }
                <div className="col-md-6">
                  <strong>Datos del contribuyente</strong> <br />
                  <strong>Nombre contribuyente:</strong> {profile.businessName} <br />
                  <strong>RFC contribuyente:</strong> {profile.rfc} <br />
                  {
                    regimeSelected && (
                      <Fragment>
                        <strong>Regimen:</strong> {regimeSelected.satCode} - {regimeSelected.officialName} <br />
                      </Fragment>
                    )
                  }
                </div>
              </div>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-income-tab" data-toggle="tab" href="#nav-income" role="tab" aria-controls="nav-income" aria-selected="true">
                    Ingresos
                  </a>
                  <a className="nav-item nav-link" id="nav-complement_income-tab" data-toggle="tab" href="#nav-complement_income" role="tab" aria-controls="nav-complement_income" aria-selected="true">
                    Complementos Ingresos
                  </a>
                  <a className="nav-item nav-link" id="nav-expenses-tab" data-toggle="tab" href="#nav-expenses" role="tab" aria-controls="nav-expenses" aria-selected="true">
                    Gastos
                  </a>
                  <a className="nav-item nav-link" id="nav-complement_expense-tab" data-toggle="tab" href="#nav-complement_expense" role="tab" aria-controls="nav-complement_expense" aria-selected="true">
                    Complementos gastos
                  </a>
                  <a className="nav-item nav-link" id="nav-retention-tab" data-toggle="tab" href="#nav-retention" role="tab" aria-controls="nav-retention" aria-selected="true">
                    Retenciones
                  </a>
                  <a className="nav-item nav-link" id="nav-taxcalculation-tab" data-toggle="tab" href="#nav-taxcalculation" role="tab" aria-controls="nav-taxcalculation" aria-selected="true">
                    Cálculo de impuestos
                  </a>
                  <a className="nav-item nav-link" id="nav-comments-tab" data-toggle="tab" href="#nav-comments" role="tab" aria-controls="nav-comments" aria-selected="true">
                    Comentarios
                  </a>
                </div>
              </nav>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-income" role="tabpanel" aria-labelledby="nav-income-tab">
                  <Income
                    formValuesDetailsSummary={formValues}
                    setFormValuesDetailsSummary={setFormValues}
                    autorizedReport={true}
                  />
                </div>
                <div className="tab-pane fade" id="nav-complement_income" role="tabpanel" aria-labelledby="nav-complement_income-tab">
                  <ComplementIncome
                    formValuesDetailsSummary={formValues}
                    setFormValuesDetailsSummary={setFormValues}
                    // refreshComplementPayments={ refreshComplementPayments }
                    autorizedReport={true}
                  // status = {status}
                  />
                </div>
                <div className="tab-pane fade" id="nav-expenses" role="tabpanel" aria-labelledby="nav-expenses-tab">
                  <Expenses
                    formValuesDetailsSummary={formValues}
                    setFormValuesDetailsSummary={setFormValues}
                    autorizedReport={true}
                  />
                </div>
                <div className="tab-pane fade" id="nav-complement_expense" role="tabpanel" aria-labelledby="nav-complement_expense-tab">
                  <Complement
                    formValuesDetailsSummary={formValues}
                    setFormValuesDetailsSummary={setFormValues}
                    // refreshComplementPayments={ refreshComplementPayments }
                    autorizedReport={true}
                  // status = {status}
                  />
                </div>
                <div className="tab-pane fade" id="nav-retention" role="tabpanel" aria-labelledby="nav-retention-tab">
                  <Retention
                    formValuesDetailsSummary={formValues}
                    setFormValuesDetailsSummary={setFormValues}
                    isrJson={JSON.parse(authorizationReport.isrJson)}
                    ivaJson={JSON.parse(authorizationReport.ivaJson)}
                  />
                </div>
                <div className="tab-pane fade" id="nav-taxcalculation" role="tabpanel" aria-labelledby="nav-taxcalculation-tab">
                  <TaxCalculation
                    formValuesDetailsSummary={formValues}
                    regimeSatCode={formValues.regimeSatCode}
                    setFormValuesDetailsSummary={setFormValues}
                    autorizedReport={true}
                  />
                </div>
                <div className="tab-pane fade" id="nav-comments" role="tabpanel" aria-labelledby="nav-comments-tab">
                  <CommentsScreen
                    formValuesDetailsSummary={formValues}
                    token={token}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}
