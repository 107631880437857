import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import Income from './options/income/income';
import Expenses from './options/expenses/expenses';
import TaxCalculation from './options/taxCalculation/taxCalculation';
import Retention from './options/retention/retention';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ACCOUNTING } from '../../../../../constants/Routes';
import { useForm } from '../../../../../../hooks/useForm';
import { expenseStartLoading } from '../../../../../../actions/accounting/expense';
import { incomesStartLoading } from '../../../../../../actions/accounting/incomes';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSatStartLoading, getRequestByPeriod, getSessionRetentionSatLoad } from '../../../../../../actions/accounting/periods';
import { retentionStartLoading } from '../../../../../../actions/accounting/retentions';
import { CommentsScreen } from './options/comments/CommentsScreen';
import { initialOptionIsrPlataform } from '../../../../../../helpers/accounting/obtainIsrPlataformPorcentaje';
import { preAutorizationReportGetByProfileId, preAutorizedReportRegisterMonthPrevStart, preAutorizedReportRegisterStart, preAutorizedReportUpdateStart } from '../../../../../../actions/accounting/preAuthorizedReports';
import { initialoptionIsr } from '../../../../../../helpers/obtainIsrPorcentage';
import { initialOptionLease } from '../../../../../../helpers/obtainLeasePorcentage';
import { initialoptionIsrHonorarium } from '../../../../../../helpers/accounting/obtainIsrHonorariumPorcentage';
import { initialoptionIsrSimplified } from '../../../../../../helpers/accounting/obtainIsrSimplifiedPorcentage';
import { obtainMonth } from '../../../../../../helpers/declarationPeriod';
import { LeaseInformation } from './informationReport/LeaseInformation';
import { PlataformInformation } from './informationReport/PlataformInformation';
import { TaxpayerInformation } from './informationReport/TaxpayerInformation';
import { taxpayerSelectedLoading } from '../../../../../../actions/taxpayers';
import { FilesScreen } from './options/files/FilesScreen';
import { HonorariumInformation } from './informationReport/HonorariumInformation';
import { SimplifiedInformation } from './informationReport/SimplifiedInformation';
import { RifInformation } from './informationReport/RifInformation';
import { CertificateDetailsScreen } from '../../../taxFiles/CertificateDetailsScreen';
import { EfirmaDetailsScreen } from '../../../taxFiles/EfirmaDetailsScreen';
import { ModalContext } from '../../../../../modal/ModalContext';
import { CaptchaForm } from './CaptchaForm';
import { CaptchaForm2 } from './CaptchaFrom2';
import { LinearProgress } from '@material-ui/core';
import { ComplianceOpinionDetailsScreen } from '../../../taxFiles/ComplianceOpinionDetailsScreen';
import Swal from 'sweetalert2';
import { UseSwal } from '../../../../../../hooks/UseSwal';
import { Complement } from './options/complement/expense/Complement';
import { ComplementIncome } from './options/complement/income/ComplementIncome';
import { sesionSat } from '../../../../../../actions/accounting/periods';

export const DetailsSummary = () => {
  // console.log("DetailSummaryJS")
  const { month, taxpayerId, regimeSatCode, year, isAdmin } = useParams();
  const { navigationPage } = UseSwal();
  const { authorizations, finishPackage, taxpayerFinishPackage } = useSelector(state => state.authorizationReport);
  // console.log(authorizations)
  const { expenses, finishExpenses } = useSelector(state => state.expense);
  const { incomes, finishIncomes } = useSelector(state => state.income);
  const { periodSelected, sesionRetentionSat } = useSelector(state => state.downloadXMLRequest);
  const { retentions, finishRetentions } = useSelector(state => state.retention);
  const { complements } = useSelector(state => state.complement);
  const { taxpayerSelected } = useSelector(state => state.taxpayer);
  const { isBoss, userName } = useSelector(state => state.auth.user);

  const [dataTaxPayer, setData] = useState([]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  let history = useHistory();

  const { handleModal } = useContext(ModalContext);

  const { commentJson, isrJson, ivaJson, resume, id, period, taxDocuments, status } = authorizations;
  const { datos, setDatos } = useForm({
    regimenId: '',
    activityId: '',
  });


  const [periodLoaded, setPeriodLoaded] = useState(false);
  const [informationToLoaded, setInformationToLoaded] = useState(false);
  const [expensesLoaded, setExpensesLoaded] = useState(false);
  const [incomesLoaded, setIncomesLoaded] = useState(false);
  const [retentionsLoaded, setRetentionsLoaded] = useState(false);
  const [loadAll, setLoadAll] = useState(false);
  const { formValues, setFormValues } = useForm({
    comments: null,
    comment: '',
    informationDiots: [],
    depresiations: [],
    reportId: 0,

    subtotalExpenseAutorized: 0.00,
    subtotalExpenseNotAutorized: 0.00,
    subtotalExpensePersonalAutorized: 0.00,
    totalExpenseAutorized: 0.00,
    totalExpenseNotAutorized: 0.00,
    totalExpensePersonalAutorized: 0.00,
    totalIVAExpenseAutorized: 0.00,
    subtotalPredialAutorized: 0.00,

    subtotalExpenseRate0: 0.00,
    totalExpenseRate0: 0.00,
    subtotalExpenseRate8: 0.00,
    totalExpenseRate8: 0.00,
    subtotalExpenseRate16: 0.00,
    totalExpenseRate16: 0.00,

    subtotalIncomesAutorized: 0.00,
    totalIncomesAutorized: 0.00,
    totalIncomesNotAutorized: 0.00,

    subtotalIncomesGeneralPublic: 0.00,
    totalIncomesGeneralPublic: 0.00,
    subtotalIncomesRate16: 0.00,
    totalIncomesRate16: 0.00,
    subtotalIncomesRate0: 0.00,
    totalIncomesRate0: 0.00,

    totalTaxesRate16: 0.00,

    subtotalSumRateExento: 0.00,

    totalISRIncomeAutorized: 0.00,
    totalIVARetainedIncomeAutorized: 0.00,

    totalIvaTraslatedExpense16: 0.00,
    totalIvaTraslatedExpense8: 0.00,

    expenseTotalTaxeTras: 0.00,

    month: month,
    monthName: '',
    isBlindDeduction: false,

    regimeSatCode: regimeSatCode,

    taxISRHonorarium: {
      expensesAccumulateds: 0.00,
      expensesAccumulatedsTotal: 0.00,
      expensesSubtotalAutorized: 0.00,

      incomesAccumulateds: 0.00,
      incomesAccumulatedsTotal: 0.00,
      incomesSubtotalAutorized: 0.0,

      depresiationFixedAsset: 0.00,
      depresiationAcumulated: 0.00,
      depresiations: [],

      nameMonth: '',
      totalISRIncomeAutorized: 0.00,
      retentionPrevMonths: 0.00,
      retentionPaidPrev: 0.00,

      taxableBase: 0.00,
      ISR: initialoptionIsrHonorarium
    },
    taxIVAHonorarium: {
      subtotalIncome16: 0.00,
      subtotalIncome0: 0.00,
      subtotalIncomeExento: 0.00,
      totalIvaCreditable: 0.00,
      totalIvaRetained: 0.00,
      totalTaxe16: 0.00,
      IVAMonthsPrev: 0.00,
      subtotalIVA: 0.00,
      totalIVA: 0.00,
    },
    taxIVASimplified: {
      subtotalIncome16: 0.00,
      subtotalIncome0: 0.00,
      subtotalIncomeExento: 0.00,
      totalIvaCreditable: 0.00,
      totalIvaRetained: 0.00,
      totalTaxe16: 0.00,
      IVAMonthsPrev: 0.00,
      subtotalIVA: 0.00,
      totalIVA: 0.00,
    },
    taxISRSimplified: {
      expensesAccumulateds: 0.00,
      expensesAccumulatedsTotal: 0.00,
      expensesSubtotalAutorized: 0.00,

      incomesAccumulateds: 0.00,
      incomesAccumulatedsTotal: 0.00,
      incomesSubtotalAutorized: 0.00,

      nameMonth: '',

      ISR: initialoptionIsrSimplified
    },
    taxISRPlataformsTechnologies: {
      serviceAlienation: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceAlineationClasified: 0.00,
        subtotalServiceLendingClasified: 0.00,
        totalServiceAlienationFinal: 0.00,
        totalServiceLendingFinal: 0.00,
        totalAlienationPlataform: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalIsr: 0.00,
        totalLendingPlataform: 0.00,
        totalService: 0.00,
        totalRetained: 0.00,
      },
      serviceGround: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceDealerClasified: 0.00,
        subtotalServicePassengersClasified: 0.00,
        totalIsr: 0.00,
        totalService: 0.00,
        totalServicePassengersFinal: 0.00,
        totalServiceDealerFinal: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalDealerPlataform: 0.00,
        totalPassengerPlataform: 0.00,
        totalRetained: 0.00,
      },
      serviceLodging: {
        ISR: initialOptionIsrPlataform,
        subtotalServiceLodgingClasified: 0.00,
        totalServiceLodgingFinal: 0.00,
        totalForIntermediaries: 0.00,
        totalForUsers: 0.00,
        totalIsr: 0.00,
        totalLodgingPlataform: 0.00,
        totalService: 0.00,
        totalRetained: 0.00,
      },
      totalIncomes: 0.00,
      totalForIntermediaries: 0.00,
      totalForUsers: 0.00,
    },
    taxIVAPlataformsTechnologies: {
      ivaCaused: 0.00,
      ivaExpenseTotal: 0.00,
      ivaPeriodsPrevius: 0.00,
      ivaToDeclared: 0.00,
      optionIva: {
        porcentage: .16
      },
      retentionPlataform: 0.00,
      totalIva: 0.00,
    },
    taxISRRIF: {
      calculatedBase: 0.00,
      calculatedBaseTotal: 0.00,
      deductionIsr: 0.00,
      expensesHigherToIncome: 0.00,
      expensesHigherToIncomePrev: 0.00,
      expensesHigherToIncomePrevFinal: 0.00,
      ISR: initialoptionIsr,
      isrDetermined: 0.00,
      isrMarginal: 0.00,
      reductionAmount: 0.00,
      subtotalIncomes: 0.00,
      subtotalExpenseAutorized: 0.00,
      subtotalIncomesAutorized: 0.00,
      totalIsr: 0.00,
      utility: 0.00
    },
    taxIVARIF: {
      deductionIsr: 0.00,

      ivaReduced: 0.00,

      ivaCharged: 0.00,
      ivaCreditable: 0.00,

      ivaPublicGeneral: 0.00,
      ivaRetained: 0.00,

      ivaToCharge: 0.00,
      IVAMonthsPrev: 0.00,

      totalTax16Expense: 0.00,
      totalTaxforeignExpense: 0.00,

      subtotalExpense0: 0.00,
      subtotalExpense16: 0.00,
      subtotalExpenseForeign: 0.00,
      subtotalIncomes0: 0.00,
      subtotalIncomes16: 0.00,
      subtotalIncomesExento: 0.00,
      subtotalIncomesPublicGeneral: 0.00,
      totalIva: 0.00,
      totalLIVA: 1.00,
    },
    taxISRLease: {
      depresiationFixedAsset: 0.00,
      depresiations: [],
      blindDeduction: 0.00,
      blindPorcentage: 0.35,
      ISR: initialOptionLease,
      isBlindDeduction: true,
      subtotalPredialExpense: 0.00,
      taxableBase: 0.00,
      TotalISRRetained: 0.00,
      totalISR: 0.00,
      subtotalIncomesAutorized: 0.00,
      subtotalExpenseAutorized: 0.00,
    },
    taxIVALease: {
      IVAMonthsPrev: 0.00,
      totalRateIncome16: 0.00,
      totalRateinedIncomeIVA: 0.00,
      totalIvaTraslated: 0.0,
      totalIVAExpense: 0.00,
      subtotalIvaLease: 0.00,
      totalIVA: 0.00
    },
    taxpayerId: taxpayerId,
    year: year,
    ISRPlataform: {}
  });

  const { ISRPlataform, taxISRHonorarium, taxIVAHonorarium, taxISRLease, taxIVALease, taxISRRIF, taxIVARIF, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies, comment, taxISRSimplified, taxIVASimplified } = formValues;

  useEffect(() => {
    if (finishPackage)
      navigationPage(history, taxpayerFinishPackage)
  }, [finishPackage])

  /* useEffect(() => {

        if(!loadAll && sesionSat && captcha){
         handleModal(<DownloadXmlLoading/>)
        }
        if(loadAll && sesionSat){
          handleModal()
        }

  }, [loadAll,sesionSat])  */

  useEffect(() => {
    if (finishIncomes && finishRetentions && finishExpenses) {
      setTimeout(() => {
        setLoadAll(true)
      }, 3000);
    }

  }, [finishIncomes, finishRetentions, finishExpenses])


  useEffect(() => {
    if (Object.keys(taxpayerSelected).length === 0 && sesionSat) {
      dispatch(taxpayerSelectedLoading(taxpayerId, regimeSatCode));
      setData(taxpayerSelected);
    }
  }, [dispatch, taxpayerId, regimeSatCode, sesionSat]); // Se elimina variable ' taxpayerSelected,' por que se sicla si no encuentra regsitros apra profileId
  // }, [ dispatch, taxpayerId, taxpayerSelected, sesionSat ]);

  useEffect(() => {
    setInformationToLoaded(false)
    if (month && taxpayerId && year) {
      setInformationToLoaded(true)
    }
  }, [month, setInformationToLoaded, taxpayerId, year])

  useEffect(() => {
    if (month) {
      setFormValues(prevState => {
        return {
          ...prevState,
          monthName: obtainMonth(parseInt(month) - 1)
        }
      })
    }
  }, [month, setFormValues])

  useEffect(() => {
    if (commentJson) {
      setFormValues(prevState => {
        return {
          ...prevState,
          comments: commentJson
        }
      })
    }
  }, [commentJson, setFormValues]);

  // }, [dispatch, isrJson, ISRPlataform, ivaJson, month, regimeSatCode, taxpayerId, taxISRHonorarium, taxIVAHonorarium, taxISRLease, taxIVALease, taxISRRIF, taxIVARIF, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies, year,sesionSat ]);

  useEffect(() => {
    let petition = {
      reportId: id,
    }
    if (Object.keys(isrJson).length > 2 && Object.keys(ivaJson).length > 2) {
      if (regimeSatCode === '625') {
        petition['taxISRPlataformsTechnologies'] = { ...JSON.parse(isrJson) }
        petition['taxIVAPlataformsTechnologies'] = { ...JSON.parse(ivaJson) }
        // petition['Resume'] = {...JSON.parse(resume)}
      }
      if (regimeSatCode === '621') {
        petition['taxISRRIF'] = { ...JSON.parse(isrJson) }
        petition['taxIVARIF'] = { ...JSON.parse(ivaJson) }
      }
      if (regimeSatCode === '606') {
        petition['taxISRLease'] = { ...JSON.parse(isrJson) }
        petition['taxIVALease'] = { ...JSON.parse(ivaJson) }
      }
      if (regimeSatCode === '612') {
        petition['taxISRHonorarium'] = { ...JSON.parse(isrJson) }
        petition['taxIVAHonorarium'] = { ...JSON.parse(ivaJson) }
      }
      if (regimeSatCode === '626') {
        petition['taxISRSimplified'] = { ...JSON.parse(isrJson) }
        petition['taxIVASimplified'] = { ...JSON.parse(ivaJson) }
      }
    }
    setFormValues(prevState => {
      return {
        ...prevState,
        ...petition
      }
    })
  }, [id, isrJson, ivaJson, regimeSatCode, setFormValues, authorizations]);

  useEffect(() => {
    if (!periodLoaded && !periodSelected && informationToLoaded && sesionSat) {
      dispatch(getRequestByPeriod({ month: month, profileId: taxpayerId, year: year }));
      setPeriodLoaded(true);
    }
  }, [dispatch, informationToLoaded, month, taxpayerId, periodLoaded, periodSelected, year, sesionSat]);


  useEffect(() => {
    if (informationToLoaded && sesionSat) {
      const date = new Date(year, month, 0);
      let dateDownloaded = {
        paymentAt: moment(date).format('MM-DD-YYYY'),
        profileId: taxpayerId,
        regimeSatCode: regimeSatCode
      }
      if (incomes.length === 0 && !incomesLoaded) {
        dispatch(incomesStartLoading(dateDownloaded));
        setIncomesLoaded(true);
      }
      if (expenses.length === 0 && !expensesLoaded) {
        dispatch(expenseStartLoading(dateDownloaded, refreshComplementPayments));
        setExpensesLoaded(true);
      }
      if (retentions.length === 0 && !retentionsLoaded) {
        dispatch(retentionStartLoading(dateDownloaded));
        setRetentionsLoaded(true);
      }

    }
  }, [dispatch, expenses, expensesLoaded, incomes, incomesLoaded, complements, informationToLoaded, month, regimeSatCode, retentions, retentionsLoaded, taxpayerId, year, sesionSat]);

  useEffect(() => {
    if (sesionRetentionSat) {
      const date = new Date(year, month, 0);
      let dateDownloaded = {
        paymentAt: moment(date).format('MM-DD-YYYY'),
        profileId: taxpayerId,
        regimeSatCode: regimeSatCode
      }
      dispatch(retentionStartLoading(dateDownloaded));
    }
  }, [month, regimeSatCode, taxpayerId, year, sesionRetentionSat])


  // Refresca la informacion de Complementos de Gastos
  const refreshComplementPayments = (onSuccess = null) => {
    let petition = {
      profileId: taxpayerId,
      satCode: regimeSatCode,
      periodMonth: month,
      periodYear: year,
      commentsJson: {},
    };
    petition.isrJson = JSON.stringify({});
    petition.ivaJson = JSON.stringify({});
    petition.Resume = JSON.stringify({})

    dispatch(preAutorizedReportRegisterStart(petition, onSuccess))
  }

  const handleSubmit = (comment) => {
    let petition = {
      reportId: id,
      comment: {
        whoComment: `Cont. ${userName}`,
        comments: comment,
        dateResponse: moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
      },
    }
    dispatch(preAutorizedReportUpdateStart(petition, true))
  }


  const activationReportPresenta = () => {
    Swal.fire({
      title: "Motivo",
      text: "Escribe el motivo de apertura",
      input: 'textarea',
      confirmButtonText: 'Enviar',
    }).then((result) => {
      if (result.value) {
        handleSubmit(result.value)
      }
    });
  }

  useEffect(() => {

    if (Object.keys(isrJson).length === 0 && Object.keys(ivaJson).length === 0 && sesionSat) {

      let petition = {
        profileId: taxpayerId,
        satCode: regimeSatCode,
        periodMonth: month,
        periodYear: year,
        commentsJson: {},
      };
      petition.isrJson = JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({})

      refreshComplementPayments()

      let monthPrev = parseInt(month) - 1;
      let yearPrev = parseInt(year);

      if (regimeSatCode === '621') {
        monthPrev = parseInt(month) - 2;
        if (monthPrev === 0) {
          monthPrev = 12;
          yearPrev = parseInt(year) - 1;
        }
      }

      if (parseInt(month) === 1) {
        monthPrev = 12;
        yearPrev = parseInt(year) - 1;
      }

      petition['periodMonth'] = monthPrev;
      petition['periodYear'] = yearPrev;

      dispatch(preAutorizedReportRegisterMonthPrevStart(petition));
    }
  }, [sesionSat]);

  const descargaXML = () => {
    Swal.fire({
      title: "Este proceso descargará todos los XML (Ingresos y gastos) del contribuyente, que estén comprendidos dentro del periodo del reporte.",
      text: `Este proceso podría tardar algún tiempo dependiendo de la cantidad de documentos a descargar. ¿Deseas continuar?`,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    }).then((result) => {
      setLoading(false);
      if (result.value) {
        setShowComponent(true);
        setLoading(true);
        dispatch(downloadSatStartLoading({ month: month, profileId: taxpayerId, year: year, requestType: '1', captcha: 'GNJHKJ' }, handleModal, "Verificando sesión", refreshComplementPayments))
      }
    });
  }


  const downloadGeneralPublic = () => {
    dispatch(downloadSatStartLoading({ month: month, profileId: taxpayerId, year: year, requestType: '3', captcha: 'GNJHKJ' }, handleModal.bind(), "Verificando Sesión"));
    handleModal(
      <CaptchaForm2
        month={month}
        profileId={taxpayerId}
        year={year}
        requestType='3'
        id='imgRete'
      />,
    )
  }




  const downloadRetentions = () => {
    dispatch(downloadSatStartLoading({ month: month, profileId: taxpayerId, year: year, requestType: '2', captcha: 'GNJHKJ' }, handleModal.bind(), "Verificando Sesión"));
    handleModal(
      <CaptchaForm2
        month={month}
        profileId={taxpayerId}
        year={year}
        requestType='2'
        id='imgRete'
      />,
    )
  }

  const [activeTab, setactiveTab] = useState('')

  const handleTabClick = (tabIde) => {
    setactiveTab(tabIde);
  }

  return (
    <>
      <>
        {showComponent && (
          <div className="alert alert-primary text-center " role="alert">
            <div className="text-center" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <hr />
              <div className="text-center my-3 mt-3">
                <p className="b-0">OBTENIENDO DATOS DEL SAT</p>
                <p className="bottom-0">Por favor espere un momento</p>
                {loading && <LinearProgress />} {/* Muestra LinearProgress si loading es true */}
              </div>
              <hr />
            </div>
          </div>
        )}
      </>

      {!loadAll && sesionSat &&
        <div className="alert alert-primary text-center " role="alert">
          <div className="text-center" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <hr />
            <div className="text-center my-3 mt-3">
              <p className="b-0">Procesando los calculos del reporte y clasificando</p>
              <p className="bottom-0">Por favor espere un momento</p>
              <LinearProgress />
            </div>
            <hr />
          </div>
        </div>
      }
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <h4>
                      DETALLE DE RESUMEN ANUAL - {period}
                    </h4>
                  </div>
                  {
                    !isAdmin &&
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      <Link to={ACCOUNTING(taxpayerId, regimeSatCode, year)} className="btn btn-danger btn-sm float-right">
                        <i className="fas fa-arrow-left"></i> Regresar
                      </Link>
                    </div>
                  }
                  {isBoss && status === 'Presentada' ? (
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 float-rigth">
                      <button className="btn btn-danger float-right" data-toggle="tooltip" data-placement="top" title="El reporte ha sido presentado, para volver a trabajarlo da clic"
                        onClick={() =>
                          activationReportPresenta()
                        }
                      >
                        Habilitar reporte
                      </button>
                    </div>
                  ) : (
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 float-rigth">
                      <div className="float-right bg-secondary">
                        <span className="float-right p-1 text-white">{status}</span>
                      </div>
                      <div className="float-right">
                        <span className="float-right p-1">Estatus</span>
                      </div>
                      <button className="btn btn-danger float-right" data-toggle="tooltip" data-placement="top" title="Descarga XML" style={{ marginRight: '4px' }}
                        onClick={() =>
                          descargaXML()
                        }
                      >
                        Descarga de XML
                      </button>
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="row">
              <div className="col-md-6">
                {formValues.regimeSatCode === '625' &&
                  <PlataformInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '606' &&
                  <LeaseInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '612' &&
                  <HonorariumInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '621' &&
                  <RifInformation
                    detailsFormValues={formValues}
                  />
                }
                {formValues.regimeSatCode === '626' &&
                  <SimplifiedInformation
                    detailsFormValues={formValues}
                  />
                }
              </div>
              <div className="col-md-6">
                <TaxpayerInformation
                  regimeSatCode={regimeSatCode}
                  taxpayer={taxpayerSelected}
                  year={year}
                  month={month}
                />
              </div>
            </div>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-income-tab" data-toggle="tab" href="#nav-income" role="tab" aria-controls="nav-income" aria-selected="true" onClick={() => handleTabClick('nav-income')}>
                  Ingresos
                </a>
                <a className="nav-item nav-link" id="nav-complement_income-tab" data-toggle="tab" href="#nav-complement_income" role="tab" aria-controls="nav-complement_income" aria-selected="true" onClick={() => handleTabClick('nav-CompIncome')}>
                  Complementos Ingresos
                </a>
                <a className="nav-item nav-link" id="nav-expenses-tab" data-toggle="tab" href="#nav-expenses" role="tab" aria-controls="nav-expenses" aria-selected="true">
                  Gastos
                </a>
                <a className="nav-item nav-link" id="nav-complement_expense-tab" data-toggle="tab" href="#nav-complement_expense" role="tab" aria-controls="nav-complement_expense" aria-selected="true">
                  Complementos gastos
                </a>
                <a className="nav-item nav-link" id="nav-retention-tab" data-toggle="tab" href="#nav-retention" role="tab" aria-controls="nav-retention" aria-selected="true">
                  Retenciones
                </a>
                <a className="nav-item nav-link" id="nav-taxcalculation-tab" data-toggle="tab" href="#nav-taxcalculation" role="tab" aria-controls="nav-taxcalculation" aria-selected="true" onClick={() => handleTabClick('nav-taxCalculation')}>
                  Cálculo de impuestos
                </a>
                <a className="nav-item nav-link" id="nav-comments-tab" data-toggle="tab" href="#nav-comments" role="tab" aria-controls="nav-comments" aria-selected="true">
                  Comentarios
                </a>
                <a className="nav-item nav-link" id="nav-tax-files-tab" data-toggle="tab" href="#nav-tax-files" role="tab" aria-controls="nav-tax-files" aria-selected="true">
                  Archivos fiscales
                </a>
                <a className="nav-item nav-link" id="nav-files-tab" data-toggle="tab" href="#nav-files" role="tab" aria-controls="nav-files" aria-selected="true">
                  Documentos fiscales
                </a>
              </div>
            </nav>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-income" role="tabpanel" aria-labelledby="nav-income-tab">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 float-rigth">
                </div>
                <Income
                  formValuesDetailsSummary={formValues}
                  setFormValuesDetailsSummary={setFormValues}
                  status={status}
                />
              </div>
              <div className="tab-pane fade" id="nav-complement_income" role="tabpanel" aria-labelledby="nav-complement_income-tab">
                <ComplementIncome
                  formValuesDetailsSummary={formValues}
                  setFormValuesDetailsSummary={setFormValues}
                  refreshComplementPayments={refreshComplementPayments}
                  status={status}
                />
              </div>
              <div className="tab-pane fade" id="nav-expenses" role="tabpanel" aria-labelledby="nav-expenses-tab">
                <Expenses
                  status={status}
                  formValuesDetailsSummary={formValues}
                  refreshComplementPayments={refreshComplementPayments}
                  setFormValuesDetailsSummary={setFormValues}
                />
              </div>
              <div className="tab-pane fade" id="nav-complement_expense" role="tabpanel" aria-labelledby="nav-complement_expense-tab">
                <Complement
                  formValuesDetailsSummary={formValues}
                  setFormValuesDetailsSummary={setFormValues}
                  refreshComplementPayments={refreshComplementPayments}
                  status={status}
                />
              </div>
              <div className="tab-pane fade" id="nav-retention" role="tabpanel" aria-labelledby="nav-retention-tab">
                {isBoss && status === 'Presentada' ? (
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 float-rigth">
                  </div>
                ) : (
                  <button id='btnDownloadRetentions' className="btn btn-sm btn-primary" onClick={() => downloadRetentions()}>
                    Descargar Retenciones
                  </button>
                )
                }
                <div>

                </div>
                <Retention
                  formValuesDetailsSummary={formValues}
                  setFormValuesDetailsSummary={setFormValues}
                  isrJson={taxISRPlataformsTechnologies}
                  ivaJson={taxIVAPlataformsTechnologies}
                />
              </div>
              <div className="tab-pane fade" id="nav-taxcalculation" role="tabpanel" aria-labelledby="nav-taxcalculation-tab">
                <TaxCalculation
                  formValuesDetailsSummary={formValues}
                  setFormValuesDetailsSummary={setFormValues}
                  status={status}
                  refreshComplementPayments={refreshComplementPayments}
                  activeTab={activeTab}
                  setactiveTab={setactiveTab}
                />
              </div>
              <div className="tab-pane fade" id="nav-comments" role="tabpanel" aria-labelledby="nav-comments-tab">
                <CommentsScreen
                  formValuesDetailsSummary={formValues}
                  status={status}
                />
              </div>

              <div className="tab-pane fade" id="nav-tax-files" role="tabpanel" aria-labelledby="nav-tax-files-tab">
                <div className="row">
                  <CertificateDetailsScreen
                    isAdmin={true}
                    taxpayerId={taxpayerId}
                  />

                  <EfirmaDetailsScreen
                    isAdmin={true}
                    taxpayerId={taxpayerId}
                  />
                </div>
                <div className="row">
                  <ComplianceOpinionDetailsScreen
                    isAdmin={true}
                    taxpayerId={taxpayerId}
                  />

                </div>
              </div>

              <div className="tab-pane fade" id="nav-files" role="tabpanel" aria-labelledby="nav-files-tab">
                <FilesScreen
                  month={month}
                  reportId={id}
                  taxDocuments={taxDocuments}
                  taxpayerId={taxpayerId}
                  year={year}
                  status={status}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
